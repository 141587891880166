<template>
  <v-container>
    <base-section id="map" space="0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9021.980210711114!2d106.81920560369471!3d-6.239747215464971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3fd352e91f3%3A0x754e29ffa038ff2f!2sM%20TEN!5e0!3m2!1sen!2sid!4v1716353221647!5m2!1sen!2sid"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </base-section>
  </v-container>
</template>

<script>
export default {
  name: "SectionMap",

  provide: {
    heading: { align: "center" },
  },
};
</script>
